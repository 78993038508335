@import url(https://fonts.googleapis.com/css?family=Roboto:400);

/* Base fontsize is 10px  */
html {
  font-size: 62.5%;
}

/* Daily Report */

.wrapper {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;

  /* height: 100vh; */
}

.daily-report {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.report {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reportTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
}

.currDate {
  font-weight: normal;
  background: #fafafa;
}

.btn {
  background-color: white;
  color: #454545;
  border: none;
  outline: 0;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 50%;
}

.done1 {
  text-decoration: line-through yellow;
}
.done1 #btn1 {
  background-color: yellow;
}
.done2 #btn2 {
  background-color: #ffbebe;
}
.done2 {
  text-decoration: line-through #ffbebe;
}

.dash-board {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: green; */
  margin: 100px auto;
  width: 50%;
  height: 50%;
}

.begin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: yellow; */
  margin: 80px auto 40px auto;
  width: 50%;
  height: 50%;
  font-size: 1.2rem;
}

.begin-button button {
  background-color: #ffeb38;
  color: #454545;
  /* border: 2px solid #454545; */
  outline: none;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  border-radius: 5%;
  font-family: 'Rajdhani', sans-serif;
  width: 65%;
}

.specifications {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.progressBar {
  max-width: 150px;
  margin: 30px 5px 5px 5px;
  font-style: normal;
  font-weight: normal;
  color: #4f4f4f;
  font-size: 30px;
  line-height: 38px;
}

.specProBar {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
}

.specH1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #4f4f4f;
}

/* Color of Habit */

.initial {
  background: white;
}
.greenText {
  background-color: green;
  border-radius: 5px;
  color: green;
}

.redText {
  background-color: red;
  border-radius: 5px;
  color: red;
}

.nav {
  background: black;
}
.navtext {
  color: #ffeb38;
}
